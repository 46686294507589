import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PageIndex from './pages/index'
import PageOrder from './pages/order'
import PageReport from './pages/report'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageIndex />} />
        <Route path="/report" element={<PageReport />} />
        <Route path="/order" element={<PageOrder />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
