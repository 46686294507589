const domainMap = new Map([
  [2, { name: '智商测试', url: 'https://iqt.ijianshui.com/result' }],
  [3, { name: '性格测试', url: 'https://mbti.ijianshui.com/result' }],
  [6, { name: '姓名配对', url: 'https://l1.ijianshui.com/result' }],
  [9, { name: '姻缘测试', url: 'https://yanyuan.ijianshui.com/result' }],
  [10, { name: '合婚配对', url: 'https://hehun.ijianshui.com/result' }],
  [11, { name: '情感运势', url: 'https://qinggan.ijianshui.com/result' }],
])

const domainMapTest = new Map([
  [2, { name: '智商测试', url: 'http://test-iqt.ijianshui.com/result' }],
  [3, { name: '性格测试', url: 'http://test-mbti.ijianshui.com/result' }],
  [6, { name: '姓名配对', url: 'http://test-xmpd2.ijianshui.com/result' }],
  [9, { name: '姻缘测试', url: 'http://test-yanyuan.ijianshui.com/result' }],
  [10, { name: '合婚配对', url: 'http://test-hehun.ijianshui.com/result' }],
  [11, { name: '情感运势', url: 'http://test-qinggan.ijianshui.com/result' }],
])

export const domianConfig =
  process.env.REACT_APP_SECRET_CODE === 'production' ? domainMap : domainMapTest

export const domain =
  process.env.REACT_APP_SECRET_CODE === 'production'
    ? 'https://inall.ijianshui.com'
    : 'http://test-inall.ijianshui.com'
