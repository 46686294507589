import { Search } from 'auto-libs'
import { Cell, Layout } from 'auto-ui'
import { useEffect } from 'react'
import { useSetState } from 'react-use'
import { domain, domianConfig } from '../../utils/config'
import http from '../../utils/http'
import Ad from './components/ad'
import './style.scss'

type qNumber = 'qone' | 'qtwo' | 'qthree' | ''

interface IState {
  loading: boolean
  errorMsg: string
  orderNo: string
  list: any
  qNumber: qNumber
  hasOrder: boolean
  productType: number
}

const PageIndex = () => {
  const [state, setState] = useSetState<IState>({
    loading: false,
    errorMsg: '',
    orderNo: '',
    list: [],
    qNumber: '',
    hasOrder: false,
    productType: 0,
  })

  const fetchOrderByOrderNo = async () => {
    const orderNo = Search.get('orderNo')
    if (!orderNo) {
      return
    }

    const result: any = await http.request({
      url: '/order/orderList',
      method: 'POST',
      data: {
        orderId: orderNo,
      },
    })
    const { body = [] } = result

    if (!body || !Array.isArray(body) || body.length === 0 || body[0].status !== 2) {
      return
    }

    setState({
      hasOrder: true,
      orderNo,
      productType: body[0].productType,
    })
  }

  useEffect(() => {
    fetchOrderByOrderNo()
  }, [])

  const onItemClick = (value: qNumber) => {
    setState({ qNumber: value })
  }

  return (
    <Layout className="page-report" useBottomSafeArea={false}>
      <Layout.Body>
        <div className="list">
          <Cell.Title className="title" text="请选择您的问题" />
          <Cell arrow={true} onClick={onItemClick}>
            <Cell.Row value="qone">付款后看不到页面内容？</Cell.Row>
            {state.qNumber === 'qone' && (
              <div className="acontent">
                {state.hasOrder ? (
                  <div className="y">
                    别着急，您的订单在这里，点开：
                    <a
                      href={`${(domianConfig.get(state.productType) as any).url}?orderNo=${
                        state.orderNo
                      }`}
                    >
                      {`${(domianConfig.get(state.productType) as any).url}?orderNo=${
                        state.orderNo
                      }`}
                    </a>
                  </div>
                ) : (
                  <div className="n">
                    很抱歉，我们没有查到您的订单信息，请确认下您是否付款成功。
                  </div>
                )}
              </div>
            )}
            <Cell.Row value="qtwo">已支付的订单想再看一遍，找不到了？</Cell.Row>
            {state.qNumber === 'qtwo' && (
              <div className="acontent">
                <div className="y">
                  您支付的历史订单在这里：
                  <a href={`${domain}/order`}>{domain + '/order'}</a>
                </div>
              </div>
            )}
            <Cell.Row value="qthree">感觉测的不够准是什么原因？</Cell.Row>
            {state.qNumber === 'qthree' && (
              <div className="acontent">
                <div className="y">
                  测的卦象与真相之间的关系受很多因素影响。生辰、心诚与否，测算时间、测算内容等等。不要在迷迷糊糊的状态测，让自己沉淀下来、安静下来，专心的做。心诚则灵，情真则明。用心专一，恭敬于事，则能获得灵验。
                  <br />
                  <br />
                  测算内容有很多种，不同的内容可以参考不同的产品测。
                </div>
                <br />
                <Ad
                  list={[
                    {
                      name: '八字合婚',
                      imgUrl: require('./imgs/ln/hh.png'),
                      url: 'https://hehun.ijianshui.com?channel=xvfd$71',
                    },
                    {
                      name: '姓名配对',
                      imgUrl: require('./imgs/ln/xm.png'),
                      url: 'https://l1.ijianshui.com/?channel=dmmy$73',
                    },
                    {
                      name: '姻缘测试',
                      imgUrl: require('./imgs/mj/yy.png'),
                      url: 'https://yanyuan.ijianshui.com/?channel=386v$74',
                    },
                    {
                      name: '兔年运势',
                      imgUrl: require('./imgs/mj/qg.png'),
                      url: 'https://qinggan.ijianshui.com?channel=athz$75',
                    },
                    {
                      name: '智商测试',
                      imgUrl: require('./imgs/mj/iq.png'),
                      url: 'https://iqt.ijianshui.com/?channel=b4vk$76',
                    },
                    {
                      name: 'MBTI',
                      imgUrl: require('./imgs/mj/mbti.png'),
                      url: 'https://mbti.ijianshui.com/?channel=yfzh$77',
                    },
                  ]}
                />
              </div>
            )}
          </Cell>
        </div>
      </Layout.Body>
    </Layout>
  )
}

export default PageIndex
