import cn from 'classnames'
import React from 'react'
import './style.scss'

interface IItem {
  imgUrl: string
  url: string
  desc?: string
  view?: number
  accuracy?: number
  name: string
}

interface IProps {
  mode?: 'normal' | 'large'
  title: string
  list: IItem[]
}

export default function Card(props: IProps) {
  const onClick = (item: IItem) => {
    window.location.href = item.url
  }

  return (
    <div
      className={cn('com-index-card', {
        large: props.mode === 'large',
      })}
    >
      <div className="title">{props.title}</div>

      <div className="list">
        {props.list.map((item: IItem, index: number) => {
          return (
            <div className="item" key={index} onClick={() => onClick(item)}>
              <div className="img">
                <img src={item.imgUrl} />
              </div>
              {item.desc && <div className="desc">{item.desc}</div>}
              {item.view && item.accuracy && (
                <div className="analysis">
                  <span className="view">{item.view}</span>
                  <span className="accuracy">{item.accuracy}</span>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
