import { Layout } from 'auto-ui'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import FeedBack from '../../components/feedback'
import Card from './components/card'
import './style.scss'

const data = {
  banner: [
    {
      href: 'https://hehun.ijianshui.com',
      imgUrl: require('./imgs/banner/hh.png'),
    },
    {
      href: 'https://yanyuan.ijianshui.com',
      imgUrl: require('./imgs/banner/yy.png'),
    },
    {
      href: 'https://qinggan.ijianshui.com',
      imgUrl: require('./imgs/banner/qg.png'),
    },
    {
      href: 'https://l1.ijianshui.com',
      imgUrl: require('./imgs/banner/xm.png'),
    },
  ],
  menu: [
    {
      href: 'https://qinggan.ijianshui.com',
      imgUrl: require('./imgs/menu/qg.png'),
      title: '情感运势',
    },
    {
      href: 'https://hehun.ijianshui.com',
      imgUrl: require('./imgs/menu/hh.png'),
      title: '八字合婚',
    },
    {
      href: 'https://l1.ijianshui.com',
      imgUrl: require('./imgs/menu/xm.png'),
      title: '姓名配对',
    },
    {
      href: 'https://yanyuan.ijianshui.com',
      imgUrl: require('./imgs/menu/yy.png'),
      title: '姻缘测试',
    },
  ],
  ln: [
    {
      name: '兔年运势',
      imgUrl: require('./imgs/ln/qg.png'),
      url: 'https://qinggan.ijianshui.com',
    },
    {
      name: '八字合婚',
      imgUrl: require('./imgs/ln/hh.png'),
      url: 'https://hehun.ijianshui.com',
    },
    {
      name: '姓名配对',
      imgUrl: require('./imgs/ln/xm.png'),
      url: 'https://l1.ijianshui.com',
    },
    {
      name: '姻缘测试',
      imgUrl: require('./imgs/ln/yy.png'),
      url: 'https://yanyuan.ijianshui.com',
    },
  ],
  jm: [
    {
      name: '姻缘测试',
      imgUrl: require('./imgs/mj/yy.png'),
      url: 'https://yanyuan.ijianshui.com',
    },
    {
      name: '兔年运势',
      imgUrl: require('./imgs/mj/qg.png'),
      url: 'https://qinggan.ijianshui.com',
    },
    {
      name: '智商测试',
      imgUrl: require('./imgs/mj/iq.png'),
      url: 'https://iqt.ijianshui.com',
    },
    {
      name: 'MBTI',
      imgUrl: require('./imgs/mj/mbti.png'),
      url: 'https://mbti.ijianshui.com',
    },
  ],
  hot: [
    {
      name: '兔年运势',
      imgUrl: require('./imgs/hot/qg.png'),
      url: 'https://yanyuan.ijianshui.com',
      view: 335009,
      accuracy: 98,
      desc: '国学大师为你全面预测，2023机遇良缘、危机陷阱！',
    },
    {
      name: '八字合婚',
      imgUrl: require('./imgs/hot/hh.png'),
      url: 'https://hehun.ijianshui.com',
      view: 265009,
      accuracy: 95,
      desc: '和TA结婚你会幸福么？能和TA一起领会日常的窝心浪漫么？',
    },
    {
      name: '姓名配对',
      imgUrl: require('./imgs/hot/xm.jpg'),
      url: 'https://l1.ijianshui.com',
      view: 1025009,
      accuracy: 95,
      desc: '姓名配对 揭秘专属于你自己的幸福秘籍',
    },
    {
      name: '姻缘测试',
      imgUrl: require('./imgs/hot/yy.jpg'),
      url: 'https://yanyuan.ijianshui.com',
      view: 885009,
      accuracy: 99,
      desc: '八字姻缘 看你什么时候会恋爱结婚',
    },
    {
      name: '智商测试',
      imgUrl: require('./imgs/hot/iq.jpg'),
      url: 'https://iqt.ijianshui.com',
      view: 195009,
      accuracy: 96,
      desc: '智商测试，看看你的智商能达到及格线吗',
    },
    {
      name: 'MBTI职业性格测试',
      imgUrl: require('./imgs/hot/mbti.png'),
      url: 'https://mbti.ijianshui.com',
      view: 305009,
      accuracy: 98,
      desc: 'MBTI 职业性格测试，测测您是属于那种性格的人？',
    },
  ],
}

const PageIndex = () => {
  return (
    <Layout className="page-index" useBottomSafeArea={false}>
      <Layout.Body>
        <div className="banner">
          <Swiper pagination={true} loop autoplay modules={[Pagination]}>
            {data.banner.map((item: any, index: number) => (
              <SwiperSlide key={index}>
                <a href={item.href}>
                  <img src={item.imgUrl} />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="menu">
          {data.menu.map((item: any, index: number) => {
            return (
              <div className="menu-item" key={index}>
                <a href={item.href}>
                  <img src={item.imgUrl} />
                  <h3>{item.title}</h3>
                </a>
              </div>
            )
          })}
        </div>

        <Card title="流年运程抢先看" list={data.ln} />
        <Card title="算命街" list={data.jm} />
        <Card title="热门测算" mode="large" list={data.hot} />

        <div className="more">
          <h2>资深大师 匠心巨制</h2>
          <div className="cx" />
          <div className="company">上海简水网络科技有限公司</div>
        </div>

        <FeedBack orderNo="" />
      </Layout.Body>
    </Layout>
  )
}

export default PageIndex
